<template>
  <v-row class="py-0 insidePageTopCardDetails">
    <v-col
      v-if="loading"
      cols="12"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="list-item-avatar-two-line"
      />
    </v-col>
    <v-col
      v-else
      cols="12"
      class="insidePageTopCardDetailsCol"
    >
      <div class="insideCardTextWrap">
        <div class="leftCardWrap">
          <div class="imageVehicleWrap">
            <v-img :src="items?.vehicle_model_image" />
          </div>
          <div class="cardIntroDetails">
            <p class="detailsPara">
              <span class="vehicleName">{{ items?.make }}</span>
              <span class="dataYear">
                {{ items?.model }} {{ items?.year }}
                {{ items?.registration_no }}
              </span>
              <span class="dataSep">|</span>
              <span class="imeiTextWrap">{{ items?.iot_device_imei }}</span>
            </p>
            <p class="addressWrap">
              {{ items?.current_address }}
            </p>
          </div>
        </div>
        <div class="rightCardWrap">
          <div class="lastActiveState">
            <label>Last Active</label>
            <div class="activeDataWrap">
              <StatusBadge :status="items?.status" />
              <span class="statusTime">
                <div
                  class="sync-column"
                  @click="isShowSyncDetail = !isShowSyncDetail"
                >
                  <span v-if="isShowSyncDetail">
                    {{ items?.synced_at_formatted_duration }}
                  </span>
                  <span v-else>{{ items?.synced_at | formatDate }}</span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { debounce } from "lodash";

export default {
  components: {
    StatusBadge: () => import("@/components/base/Status-Badge.vue"),
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: true,
      items: null,
      isShowSyncDetail: false,
    };
  },
  watch: {
    "options.vehicle_id": {
      handler: async function () {
        this.fetchData();
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData: debounce(function () {
      const params = {
        // vehicle_id: this.$route?.query.vehicle_id,
        ...this.options,
      };
      if (!this.options.vehicle_id) return 0;
      this.loading = true;
      this.$store
        .dispatch("telematicDetails/telematicHeaderInfo", params)
        .then((data) => {
          this.items = data;
        })
        .finally(() => (this.loading = false));
    }, 300),
  },
};
</script>
<style lang="sass" scoped>

.sync-column
  cursor: pointer
  transition: all 0.2s ease
  &:hover
    color: #20a390 !important


@media (max-width: 480px)
  .insidePageTopCardDetailsCol .insideCardTextWrap .leftCardWrap
    flex-wrap: wrap
  .insidePageTopCardDetailsCol .insideCardTextWrap .rightCardWrap 
    margin-left: unset
    margin-top: 14px

</style>
